var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var MY,Xla,OY,Yla,Zla,$la,QY,bma,cma,dma,ema,TY,UY,VY,WY,YY,gma,ZY,$Y,hma,ima,jma,SY,ama,aZ,bZ,fma,dZ;MY=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.bf(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
Xla=function(){return function(){function a(k,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.j?$CLJS.mj.j(k,l,m):$CLJS.mj.call(null,k,l,m),$CLJS.ml.j?$CLJS.ml.j(k,l,m):$CLJS.ml.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.g?$CLJS.mj.g(k,l):$CLJS.mj.call(null,k,l),$CLJS.ml.g?$CLJS.ml.g(k,l):$CLJS.ml.call(null,k,l)],null)}function c(k){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.h?$CLJS.mj.h(k):$CLJS.mj.call(null,k),$CLJS.ml.h?$CLJS.ml.h(k):$CLJS.ml.call(null,
k)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.o?$CLJS.mj.o():$CLJS.mj.call(null),$CLJS.ml.o?$CLJS.ml.o():$CLJS.ml.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var z=Array(arguments.length-3);x<z.length;)z[x]=arguments[x+3],++x;x=new $CLJS.w(z,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Se.N($CLJS.mj,m,t,u,v),$CLJS.Se.N($CLJS.ml,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.A(m);m=$CLJS.B(m);var u=$CLJS.A(m);m=$CLJS.B(m);var v=$CLJS.A(m);m=$CLJS.Lc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};
$CLJS.NY=function(a){return function(b){var c=$CLJS.$e(-1);return function(){function d(l,m){var t=c.Dd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
OY=function(a){return $CLJS.$L($CLJS.uW)(a,$CLJS.Cf,$CLJS.Cf)};Yla=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.eG,b),$CLJS.ot(2,2,c))};Zla=function(a,b){var c=$CLJS.fm(a,$CLJS.QQ);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Fb(c);b=$CLJS.It.g?$CLJS.It.g(c,b):$CLJS.It.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.QQ,b)}return a};$la=function(a){return $CLJS.gf.g($CLJS.VV(a),$CLJS.WV(a))};
$CLJS.PY=function(a,b){return $CLJS.GW(a)&&$CLJS.E.g($CLJS.A(a),b)};QY=function(a,b,c){var d=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.Ws,null,$CLJS.tl,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.Cu(2,c):$CLJS.Cu(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?MY(2,c):MY(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.EX.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.zd(k)?$CLJS.eg.j($CLJS.Cf,$CLJS.Qk.h($CLJS.Al),l):$CLJS.xd(k)?$CLJS.NA($CLJS.Wa,l):l}):$CLJS.Hk.g(a,f)};
bma=function(a){return $CLJS.fb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return QY(b,ama,d)},a,$la(a))};
cma=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.wL.h(a),b);a:{var d=c;for(var e=$CLJS.Cf;;){var f=$CLJS.A($CLJS.hf.g(Xla(),$CLJS.lf($CLJS.Pk.j(RY,$CLJS.A,$CLJS.ml),$CLJS.$s.h(OY(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=QY(d,f,k),m=$CLJS.QA($CLJS.Ly);if($CLJS.n($CLJS.PA("metabase.lib.convert",m))){var t=$CLJS.HB.l($CLJS.H([$CLJS.Gz,$CLJS.Mh.l($CLJS.H([k]))])),u=$CLJS.HB,v=u.l,x=$CLJS.Mh,z=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.eM(OY(d));$CLJS.OA("metabase.lib.convert",
m,$CLJS.DW("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.Gz,z.call(x,$CLJS.H([C]))])),$CLJS.HB.l($CLJS.H([$CLJS.vz,$CLJS.EW($CLJS.A($CLJS.ju(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.be.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=bma(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.wL.h(a))-1))return a;b+=1}else a=$CLJS.Sk.N(a,$CLJS.wL,$CLJS.R,b,d)}};
dma=function(a){var b=$CLJS.RW();return $CLJS.Rk.g(function(c){return $CLJS.E.g($CLJS.cF.h(c),"__join")?$CLJS.Sk.j(c,$CLJS.cF,b):c},a)};ema=function(a){return"string"===typeof $CLJS.oP.h(a)?$CLJS.Hk.g($CLJS.R.j(a,$CLJS.XV,$CLJS.PW($CLJS.oP.h(a))),$CLJS.oP):a};TY=function(a,b){var c=$CLJS.J.j(a,b,SY);if($CLJS.E.g(c,SY))throw $CLJS.hi(["Unable to find ",$CLJS.Mh.l($CLJS.H([b]))," in map."].join(""),new $CLJS.h(null,2,[fma,a,$CLJS.bx,b],null));return c};
UY=function(){return $CLJS.Qk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.me(b))?(b=$CLJS.ie(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};VY=function(a){return $CLJS.eg.j($CLJS.N,UY(),a)};WY=function(a){return $CLJS.Fe($CLJS.eg.j($CLJS.N,$CLJS.Pk.g(UY(),$CLJS.Qk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.Fi)})),a))};
YY=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.h($CLJS.XY),c);a=$CLJS.E.g(a,$CLJS.ZF)&&$CLJS.B(c)?$CLJS.be.g($CLJS.sd(d),new $CLJS.h(null,1,[$CLJS.ci,$CLJS.rd(d)],null)):d;b=$CLJS.Fe(WY(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EM,a,b],null):a};gma=function(a){return $CLJS.eg.j($CLJS.Cf,$CLJS.Pk.g($CLJS.hf.h(function(b){return $CLJS.IA(b,$CLJS.MB)}),$CLJS.hf.h($CLJS.XY)),$CLJS.LW.h(a))};
ZY=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.wL);a=$CLJS.A($CLJS.fb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.XY.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.SQ,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.aP,gma(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.xV.h(c)],null)},null,a));return $CLJS.n($CLJS.tQ.h(a))?$CLJS.IV(a,new $CLJS.h(null,1,[$CLJS.tQ,$CLJS.MF],null)):a};
$Y=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.cX(a,b,$CLJS.Pk.g($CLJS.XY,$CLJS.A)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.cX(d,b,function(e){return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Gs],null),$CLJS.hf.h($CLJS.XY),e)}):d;return $CLJS.IV(a,$CLJS.Ee([b,c]))};hma=function(a){var b=$CLJS.XV.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Hk.g(a,$CLJS.XV),$CLJS.oP,["card__",$CLJS.p.h(b)].join("")):a};
ima=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);jma=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);SY=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);ama=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);aZ=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
bZ=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.cZ=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);fma=new $CLJS.M(null,"m","m",1632677161);dZ=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var RY,kma,lma,mma,nma,oma,pma,qma,rma,sma,tma;$CLJS.eZ=$CLJS.N;$CLJS.fZ=$CLJS.N;RY=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.rW,null,$CLJS.ZE,null,$CLJS.NN,null,$CLJS.jQ,null,$CLJS.dG,null,$CLJS.ZR,null,$CLJS.eG,null],null),null);kma=$CLJS.Xe($CLJS.N);lma=$CLJS.Xe($CLJS.N);mma=$CLJS.Xe($CLJS.N);nma=$CLJS.Xe($CLJS.N);oma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.nC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
$CLJS.gZ=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.lC,oma,kma,lma,mma,nma);$CLJS.gZ.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){var b=$CLJS.rd(a);var c=$CLJS.xd(b);a=c?$CLJS.sd(a):a;var d=$CLJS.y(a);a=$CLJS.A(d);d=$CLJS.B(d);b=$CLJS.yW($CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.hf.h($CLJS.gZ),d))}else b=a;return b});$CLJS.gZ.m(null,$CLJS.qW,function(a){return a});
$CLJS.gZ.m(null,$CLJS.dW,function(a){var b=$CLJS.gZ.h($CLJS.eG.h(a)),c=$CLJS.Fe($CLJS.Rk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.IW($CLJS.gZ.h(t),u)},$CLJS.jQ.h(a))),d=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.GE);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.GE);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.fZ,k=$CLJS.eZ;$CLJS.fZ=d;$CLJS.eZ=e;try{var l=Yla(ema(a),b,$CLJS.H([$CLJS.jQ,c])),m=$CLJS.fb(function(t,u){return $CLJS.Va($CLJS.J.g(t,u))?t:$CLJS.Sk.j(t,u,$CLJS.gZ)},l,$CLJS.Ik.l(RY,$CLJS.eG,$CLJS.H([$CLJS.jQ])));return $CLJS.n($CLJS.NN.h(m))?$CLJS.Sk.j(m,$CLJS.NN,dma):m}finally{$CLJS.eZ=k,$CLJS.fZ=f}});$CLJS.gZ.m(null,$CLJS.kW,function(a){return Zla(a,function(b){return $CLJS.cX(b,$CLJS.JF,$CLJS.gZ)})});
$CLJS.gZ.m(null,$CLJS.rL,function(a){a=$CLJS.Sk.j($CLJS.Sk.j(a,$CLJS.zL,$CLJS.gZ),$CLJS.wL,$CLJS.gZ);var b=$CLJS.n($CLJS.ZE.h(a))?$CLJS.Sk.j(a,$CLJS.ZE,function(c){return $CLJS.Ml(c)?$CLJS.Rk.g($CLJS.gZ,c):$CLJS.zh.h(c)}):a;return $CLJS.Va($CLJS.cF.h(a))?$CLJS.R.j(b,$CLJS.cF,"__join"):b});$CLJS.gZ.m(null,$CLJS.rB,function(a){return $CLJS.Rk.g($CLJS.gZ,a)});
$CLJS.gZ.m(null,$CLJS.uB,function(a){return $CLJS.n($CLJS.mj.h(a))?cma($CLJS.R.j($CLJS.Sk.j($CLJS.MW(a),$CLJS.wL,function(b){return $CLJS.Rk.g($CLJS.gZ,b)}),$CLJS.cZ,!0)):$CLJS.It(a,$CLJS.gZ)});$CLJS.gZ.m(null,$CLJS.pG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.yW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,a,b],null))});
$CLJS.gZ.m(null,$CLJS.Dj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.IV(a,new $CLJS.h(null,3,[$CLJS.mD,$CLJS.yB,$CLJS.NO,$CLJS.Vj,$CLJS.gS,$CLJS.BE],null));var c=$CLJS.R.j;var d=$CLJS.Fi.h(a);$CLJS.n(d)||(d=$CLJS.yB.h(a),d=$CLJS.n(d)?d:$CLJS.UE(b));a=c.call($CLJS.R,a,$CLJS.Fi,d);return $CLJS.yW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,a,b],null))});
$CLJS.gZ.m(null,$CLJS.ZF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.ci.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZF,$CLJS.Hk.g(c,$CLJS.ci),$CLJS.Rk.g($CLJS.gZ,b)],null);b=$CLJS.yW(b);return null!=a?$CLJS.be.g(b,$CLJS.gZ.h(a)):b});$CLJS.gZ.m(null,$CLJS.vB,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.yW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.gZ.m(null,$CLJS.eG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.yW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,TY($CLJS.fZ,c)],null))});$CLJS.gZ.m(null,$CLJS.EM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.gZ.h(b);var c=$CLJS.y(b);b=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.gl.l($CLJS.H([c,a]))],null),d)});pma=$CLJS.Xe($CLJS.N);qma=$CLJS.Xe($CLJS.N);
rma=$CLJS.Xe($CLJS.N);sma=$CLJS.Xe($CLJS.N);tma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.nC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.XY=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.lC,tma,pma,qma,rma,sma);
$CLJS.XY.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);if($CLJS.xd(a)){c=$CLJS.eg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.gf.g;d=$CLJS.hf.g($CLJS.XY,d);a=WY(a);a=c.call($CLJS.eg,b,e.call($CLJS.gf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.hf.g($CLJS.XY,$CLJS.ee(a,d)))}else a=$CLJS.xd(a)?$CLJS.It(VY(a),$CLJS.XY):
a;return a});for(var hZ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[dZ,bZ],null)),iZ=null,jZ=0,kZ=0;;)if(kZ<jZ){var uma=iZ.X(null,kZ);$CLJS.xF(uma,aZ);kZ+=1}else{var lZ=$CLJS.y(hZ);if(lZ){var mZ=lZ;if($CLJS.Ad(mZ)){var nZ=$CLJS.lc(mZ),vma=$CLJS.mc(mZ),wma=nZ,xma=$CLJS.D(nZ);hZ=vma;iZ=wma;jZ=xma}else{var yma=$CLJS.A(mZ);$CLJS.xF(yma,aZ);hZ=$CLJS.B(mZ);iZ=null;jZ=0}kZ=0}else break}
for(var oZ=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Yx,$CLJS.jG,$CLJS.yG,$CLJS.VF,$CLJS.jk,$CLJS.DG,$CLJS.Zn,$CLJS.aG,$CLJS.wG,$CLJS.FG,$CLJS.CG,$CLJS.KG],null)),pZ=null,qZ=0,rZ=0;;)if(rZ<qZ){var zma=pZ.X(null,rZ);$CLJS.xF(zma,dZ);rZ+=1}else{var sZ=$CLJS.y(oZ);if(sZ){var tZ=sZ;if($CLJS.Ad(tZ)){var uZ=$CLJS.lc(tZ),Ama=$CLJS.mc(tZ),Bma=uZ,Cma=$CLJS.D(uZ);oZ=Ama;pZ=Bma;qZ=Cma}else{var Dma=$CLJS.A(tZ);$CLJS.xF(Dma,dZ);oZ=$CLJS.B(tZ);pZ=null;qZ=0}rZ=0}else break}
for(var vZ=$CLJS.y($CLJS.yf([$CLJS.ds,$CLJS.rt,$CLJS.es,$CLJS.LG,$CLJS.ZF,$CLJS.sG,$CLJS.QF,$CLJS.NG,$CLJS.HF,$CLJS.cG,$CLJS.LF,$CLJS.nG,$CLJS.uG,$CLJS.NF,$CLJS.hJ,$CLJS.jJ,$CLJS.My,$CLJS.dJ,$CLJS.lJ,$CLJS.YI,$CLJS.pJ,$CLJS.cJ,$CLJS.WI,$CLJS.oJ,$CLJS.wJ,$CLJS.sJ,$CLJS.bJ,$CLJS.zJ,$CLJS.rJ,$CLJS.xJ,$CLJS.PF,$CLJS.IF,$CLJS.OG,$CLJS.hI,$CLJS.rG,$CLJS.Wx,$CLJS.GG,$CLJS.IG,$CLJS.PG,$CLJS.Lt,$CLJS.Rt],!0)),wZ=null,xZ=0,yZ=0;;)if(yZ<xZ){var Ema=wZ.X(null,yZ);$CLJS.xF(Ema,bZ);yZ+=1}else{var zZ=$CLJS.y(vZ);
if(zZ){var AZ=zZ;if($CLJS.Ad(AZ)){var BZ=$CLJS.lc(AZ),Fma=$CLJS.mc(AZ),Gma=BZ,Hma=$CLJS.D(BZ);vZ=Fma;wZ=Gma;xZ=Hma}else{var Ima=$CLJS.A(AZ);$CLJS.xF(Ima,bZ);vZ=$CLJS.B(AZ);wZ=null;xZ=0}yZ=0}else break}$CLJS.XY.m(null,aZ,function(a){return YY(a)});$CLJS.XY.m(null,$CLJS.uB,function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Pk.g(UY(),$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.XY.h(b)],null)})),a)});
$CLJS.XY.m(null,$CLJS.eG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.xd(b))try{var d=WY(b),e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,TY($CLJS.eZ,c)],null);return $CLJS.n(d)?$CLJS.be.g(e,d):e}catch(f){throw b=f,c=$CLJS.CV(b),c=$CLJS.fa.g?$CLJS.fa.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.fa.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.gi(c,new $CLJS.h(null,1,[$CLJS.W,a],null),b);}else return a});
$CLJS.XY.m(null,$CLJS.rB,function(a){return $CLJS.Rk.g($CLJS.XY,a)});$CLJS.XY.m(null,$CLJS.pG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,$CLJS.XY.h(a),WY(b)],null)});
$CLJS.XY.m(null,$CLJS.Dj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=WY($CLJS.IV(b,new $CLJS.h(null,3,[$CLJS.yB,$CLJS.mD,$CLJS.Vj,$CLJS.NO,$CLJS.BE,$CLJS.gS],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,a,b],null)});
$CLJS.XY.m(null,$CLJS.rL,function(a){var b=VY(a);a=0==$CLJS.cF.h(a).lastIndexOf("__join",0)?$CLJS.Hk.g(b,$CLJS.cF):b;return $CLJS.gl.l($CLJS.H([$CLJS.It($CLJS.Hk.l(a,$CLJS.wL,$CLJS.H([$CLJS.zL])),$CLJS.XY),$Y($CLJS.em(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.zL],null)),$CLJS.zL,$CLJS.aS),ZY(a)]))});
$CLJS.XY.m(null,$CLJS.dW,function(a){var b=$CLJS.eG.h(a),c=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.GE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.GE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.fZ,e=$CLJS.eZ;$CLJS.fZ=c;$CLJS.eZ=b;try{return $CLJS.fb(function(f,k){return $CLJS.cX(f,
k,$CLJS.XY)},$Y($CLJS.cX($CLJS.cX(hma(VY(a)),$CLJS.eG,function(f){return $CLJS.Rk.g(YY,f)}),$CLJS.jQ,function(f){return $CLJS.eg.g($CLJS.N,function(){return function m(l){return new $CLJS.ne(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z),G=$CLJS.XY.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HW(C),$CLJS.E.g($CLJS.Dj,$CLJS.A(G))?$CLJS.hd(G):G],null);x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),
m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);u=$CLJS.XY.h(x);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HW(x),$CLJS.E.g($CLJS.Dj,$CLJS.A(u))?$CLJS.hd(u):u],null),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())}),$CLJS.rW,$CLJS.UF),$CLJS.Ik.l(RY,$CLJS.eG,$CLJS.H([$CLJS.rW,$CLJS.jQ])))}finally{$CLJS.eZ=e,$CLJS.fZ=d}});$CLJS.XY.m(null,$CLJS.kW,function(a){return $CLJS.It(VY(a),$CLJS.XY)});
$CLJS.XY.m(null,$CLJS.qW,function(a){try{var b=VY(a),c=$CLJS.EN.h(b),d=ZY(b),e=$CLJS.E.g($CLJS.Ej.h($CLJS.id($CLJS.wL.h(a))),$CLJS.kW)?$CLJS.tQ:$CLJS.MF;return $CLJS.gl.l($CLJS.H([$CLJS.It($CLJS.Hk.l(b,$CLJS.wL,$CLJS.H([$CLJS.EN,$CLJS.cZ])),$CLJS.XY),function(){var k=$CLJS.Ee([$CLJS.mj,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.EN,c):k}()]))}catch(k){var f=k;throw $CLJS.gi(function(){var l=$CLJS.CV(f);return $CLJS.fa.g?$CLJS.fa.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.fa.call(null,
"Error converting MLv2 query to legacy query: %s",l)}(),new $CLJS.h(null,1,[$CLJS.MF,a],null),f);}});
$CLJS.CZ=function(){function a(d,e,f){f=$CLJS.LY(null,$CLJS.Tz(f,$CLJS.H([$CLJS.zi,!0])));var k=$CLJS.OW(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.eG);k=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.GE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.GE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.fZ,t=$CLJS.eZ;$CLJS.fZ=k;$CLJS.eZ=l;try{try{return $CLJS.gZ.h(f)}catch(v){var u=v;throw $CLJS.gi(function(){var x=$CLJS.CV(u);return $CLJS.fa.g?$CLJS.fa.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.fa.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.MF,d,$CLJS.ML,e,jma,f,ima,$CLJS.fZ],null),u);}}finally{$CLJS.eZ=t,$CLJS.fZ=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();