var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var tfa,ufa,vfa,wfa,xfa,yfa,zfa,Afa,Bfa;tfa=function(a,b){a.sort(b||$CLJS.Da)};ufa=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Da;tfa(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.aH=function(a){return $CLJS.E.g(a,$CLJS.Jd)?$CLJS.Jd:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.bH=function(a,b){if($CLJS.y(b)){var c=$CLJS.we.h?$CLJS.we.h(b):$CLJS.we.call(null,b);ufa(c,$CLJS.aH(a));return $CLJS.od($CLJS.y(c),$CLJS.pd(b))}return $CLJS.Kc};$CLJS.cH=new $CLJS.M(null,"second","second",-444702010);$CLJS.dH=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);
$CLJS.eH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.fH=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.gH=new $CLJS.M(null,"year-of-era","year-of-era",682445876);vfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
wfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.hH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.iH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);
$CLJS.jH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);xfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);yfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);
zfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);Afa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.kH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);
$CLJS.lH=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.mH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.nH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
Bfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var oH=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Gi,$CLJS.Gj,$CLJS.Fj,$CLJS.qi,$CLJS.mk,$CLJS.Ai,$CLJS.ji,$CLJS.gH],null),pH=$CLJS.fh(oH),Cfa,Dfa,Efa;$CLJS.X(zfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.yt,"Valid date extraction unit"],null)],null),pH));var qH=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fu,$CLJS.oj,$CLJS.xj,$CLJS.pi,$CLJS.ji],null),rH=$CLJS.fh(qH);
$CLJS.X(yfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.yt,"Valid date truncation unit"],null)],null),rH));$CLJS.sH=$CLJS.eg.j($CLJS.Cf,$CLJS.il.o(),$CLJS.gf.g(qH,oH));Cfa=$CLJS.fh($CLJS.sH);$CLJS.X($CLJS.jH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.yt,"Valid date bucketing unit"],null)],null),Cfa));var tH=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dH,$CLJS.ij,$CLJS.wi],null),uH=$CLJS.fh(tH);
$CLJS.X(xfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.yt,"Valid time extraction unit"],null)],null),uH));var vH=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fH,$CLJS.cH,$CLJS.Ui,$CLJS.qk],null),wH=$CLJS.fh(vH);$CLJS.X(Bfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.yt,"Valid time truncation unit"],null)],null),wH));$CLJS.xH=$CLJS.eg.j($CLJS.Cf,$CLJS.il.o(),$CLJS.gf.g(vH,tH));Dfa=$CLJS.fh($CLJS.xH);
$CLJS.X(wfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.yt,"Valid time bucketing unit"],null)],null),Dfa));$CLJS.yH=$CLJS.eg.j($CLJS.Cf,$CLJS.il.o(),$CLJS.gf.l(vH,qH,$CLJS.H([tH,oH])));Efa=$CLJS.fh($CLJS.yH);$CLJS.X($CLJS.eH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.yt,"Valid datetime bucketing unit"],null)],null),$CLJS.yH));var Ffa=$CLJS.be.g(Efa,$CLJS.ci);
$CLJS.X($CLJS.mH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.yt,"Valid temporal bucketing unit"],null)],null),Ffa));$CLJS.zH=$CLJS.gu.g(rH,wH);$CLJS.X($CLJS.hH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.yt,"Valid datetime truncation unit"],null)],null),$CLJS.zH));$CLJS.AH=$CLJS.gu.g(pH,uH);$CLJS.X(vfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.yt,"Valid datetime extraction unit"],null)],null),$CLJS.AH));
var BH=$CLJS.be.g(rH,$CLJS.ji);$CLJS.X($CLJS.nH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.yt,"Valid date interval unit"],null)],null),BH));$CLJS.X($CLJS.iH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.yt,"Valid time interval unit"],null)],null),wH));var Gfa=$CLJS.gu.g(BH,wH);$CLJS.X($CLJS.kH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.yt,"Valid datetime interval unit"],null)],null),Gfa));
$CLJS.X(Afa,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.lH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.si,$CLJS.mH],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Ps],null)],null));