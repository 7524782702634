var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var DZ,Jma,Kma,Lma,Mma,Nma,JZ,Oma,Pma,Qma,MZ,Rma,Sma,Tma,RZ,SZ,Uma,Vma,Wma,a_,Xma,Yma,Zma,d_,$ma,ana,bna,cna;DZ=function(){};$CLJS.EZ=function(a){return null!=a?$CLJS.Bc===a.ki?!0:a.Wc?!1:$CLJS.$a(DZ,a):$CLJS.$a(DZ,a)};Jma=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.FZ=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Kma=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Lma=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.GZ=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.HZ=new $CLJS.M(null,"database-id","database-id",1883826326);Mma=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.IZ=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);Nma=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);JZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Oma=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Pma=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
Qma=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.KZ=new $CLJS.M("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.LZ=new $CLJS.M(null,"dataset","dataset",1159262238);MZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Rma=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.NZ=new $CLJS.M("source","expressions","source/expressions",-458367840);
$CLJS.OZ=new $CLJS.M(null,"definition","definition",-1198729982);Sma=new $CLJS.M(null,"state","state",-1988618099);$CLJS.PZ=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Tma=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.QZ=new $CLJS.M("source","joins","source/joins",1225821486);RZ=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);
SZ=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.TZ=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.UZ=new $CLJS.M(null,"fk-target-field-id","fk-target-field-id",2019750287);Uma=new $CLJS.M("lib","external-remap","lib/external-remap",543358316);$CLJS.VZ=new $CLJS.M(null,"selected?","selected?",-742502788);
Vma=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);Wma=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.WZ=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.XZ=new $CLJS.M("source","card","source/card",-139977973);$CLJS.YZ=new $CLJS.M(null,"active","active",1895962068);$CLJS.ZZ=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);
$CLJS.$Z=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);a_=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Xma=new $CLJS.M(null,"details","details",1956795411);$CLJS.b_=new $CLJS.M("source","fields","source/fields",-649667981);Yma=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.c_=new $CLJS.M("source","native","source/native",-1444604147);
Zma=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);d_=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.e_=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);$ma=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);ana=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);
bna=new $CLJS.M("lib","internal-remap","lib/internal-remap",-220033801);cna=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.f_=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.g_=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.X(RZ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ws,$CLJS.XZ,$CLJS.c_,$CLJS.GZ,$CLJS.IZ,$CLJS.b_,$CLJS.pL,$CLJS.jL,$CLJS.QZ,$CLJS.NZ,$CLJS.PZ],null));$CLJS.X(a_,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,cna],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.cL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dL,$CLJS.nL],null)],null));
$CLJS.X(JZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$ma],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.cL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.li],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[Nma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.li],null)],null)],null));
$CLJS.X($CLJS.KZ,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.yt,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.VK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.Zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yB,$CLJS.IE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.nL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Zj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fi,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.IE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gL,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.nL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UZ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.nL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.f_,
new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.zE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.zE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XK,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.e_,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.eL],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FZ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.zE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TZ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,new $CLJS.h(null,2,[$CLJS.Zn,1,$CLJS.jk,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VZ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Ps],null),new $CLJS.P(null,3,5,$CLJS.Q,
[Uma,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a_],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[bna,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JZ],null)],null)],null)],null));
$CLJS.X(d_,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WZ,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[Rma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[Qma,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yB,$CLJS.IE],null)],null)],null)],null)],null)],null));
$CLJS.X(MZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YZ,$CLJS.Ps],null),new $CLJS.P(null,2,5,$CLJS.Q,[Sma,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WZ,$CLJS.zE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OZ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,d_],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UK,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,
$CLJS.zE],null)],null)],null));
$CLJS.X(Yma,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.yt,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.oL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.eL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HZ,$CLJS.fL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZZ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.ik],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$Z,
new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.ik],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LZ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Ps],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hL,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.$K],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Lma,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,MZ],null)],null)],null)],null));
$CLJS.X(Vma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.yt,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.ZK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.YK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hL,$CLJS.$K],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.St,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.zE],null)],null)],null));
$CLJS.X(Wma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.yt,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.aL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.WK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hL,$CLJS.$K],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.St,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.zE],null)],null)],null));
$CLJS.X(Zma,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.yt,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.bL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.$K],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.zE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.zE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ql,new $CLJS.h(null,
1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.zE],null)],null)],null));
$CLJS.X(Kma,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.yt,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.lL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.fL],null),new $CLJS.P(null,3,5,$CLJS.Q,[Pma,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Xma,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.ik],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.g_,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Xi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mA,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.Xi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Tma,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Ps],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iL,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.ik],null)],null)],null));
$CLJS.X(SZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.yt,"Valid MetadataProvider"],null),new $CLJS.Gc(function(){return $CLJS.EZ},Mma,$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.vi,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ui,$CLJS.kk,$CLJS.ok],[Jma,ana,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.$q],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.EZ)?$CLJS.EZ.H:null]))],null));
$CLJS.X(Oma,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.yt,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SZ],null)],null)],null)],null));