var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var yna,zna,Ana,Bna,Cna,e1;
$CLJS.b1=function(){function a(e,f){if($CLJS.Va(f))return"";e=$CLJS.FA(e);var k=$CLJS.zh.h(f);switch(k instanceof $CLJS.M?k.T:null){case "default":return $CLJS.HV("Default period","Default periods",e);case "millisecond":return $CLJS.HV("Millisecond","Milliseconds",e);case "second":return $CLJS.HV("Second","Seconds",e);case "minute":return $CLJS.HV("Minute","Minutes",e);case "hour":return $CLJS.HV("Hour","Hours",e);case "day":return $CLJS.HV("Day","Days",e);case "week":return $CLJS.HV("Week","Weeks",
e);case "month":return $CLJS.HV("Month","Months",e);case "quarter":return $CLJS.HV("Quarter","Quarters",e);case "year":return $CLJS.HV("Year","Years",e);case "minute-of-hour":return $CLJS.HV("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.HV("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.HV("Day of week","Days of week",e);case "day-of-month":return $CLJS.HV("Day of month","Days of month",e);case "day-of-year":return $CLJS.HV("Day of year","Days of year",e);case "week-of-year":return $CLJS.HV("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.HV("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.HV("Quarter of year","Quarters of year",e);default:return f=$CLJS.Yt($CLJS.gh(f),/-/),e=$CLJS.y(f),f=$CLJS.A(e),e=$CLJS.B(e),$CLJS.Jt(" ",$CLJS.ee($CLJS.CA(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.c1=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.nC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.lC(f)},e,a,b,c,d)}();yna=$CLJS.Xe($CLJS.N);zna=$CLJS.Xe($CLJS.N);
Ana=$CLJS.Xe($CLJS.N);Bna=$CLJS.Xe($CLJS.N);Cna=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.nC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.d1=new $CLJS.di($CLJS.Fh.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.lC,Cna,yna,zna,Ana,Bna);$CLJS.d1.m(null,$CLJS.ci,function(){return null});$CLJS.d1.m(null,$CLJS.lH,function(a){return $CLJS.si.h(a)});e1=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.cH,null,$CLJS.fH,null,$CLJS.gH,null,$CLJS.dH,null],null),null);
$CLJS.Dna=$CLJS.eg.j($CLJS.Cf,$CLJS.Pk.g($CLJS.Qk.h(e1),$CLJS.hf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.Ej,$CLJS.lH,$CLJS.si,a],null);return $CLJS.E.g(a,$CLJS.qk)?$CLJS.R.j(b,$CLJS.ci,!0):b})),$CLJS.xH);$CLJS.Ena=$CLJS.Rk.g(function(a){var b=new $CLJS.h(null,2,[$CLJS.Ej,$CLJS.lH,$CLJS.si,a],null);return $CLJS.E.g(a,$CLJS.fu)?$CLJS.R.j(b,$CLJS.ci,!0):b},$CLJS.sH);
$CLJS.Fna=$CLJS.eg.j($CLJS.Cf,$CLJS.Pk.g($CLJS.Qk.h(e1),$CLJS.hf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.Ej,$CLJS.lH,$CLJS.si,a],null);return $CLJS.E.g(a,$CLJS.fu)?$CLJS.R.j(b,$CLJS.ci,!0):b})),$CLJS.yH);$CLJS.J0.m(null,$CLJS.lH,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.si);return $CLJS.b1.h(a)});
$CLJS.Q0.m(null,$CLJS.lH,function(a,b,c){return $CLJS.gl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.uE,$CLJS.j0.j(a,b,c),$CLJS.H0,$CLJS.dB($CLJS.d1.h(c))],null),$CLJS.em(c,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ci,$CLJS.r0],null))]))});
$CLJS.f1=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.nC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,k,l){return $CLJS.lC(l)},e,a,b,c,d)}();$CLJS.f1.m(null,$CLJS.ci,function(){return $CLJS.bh});
$CLJS.g1=function(){function a(d,e,f){return $CLJS.f1.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();