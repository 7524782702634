var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var j1,Gna,Hna,k1,l1;$CLJS.h1=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.fc(c,d):c},$CLJS.ec($CLJS.Cf),b))};j1=function(a){return $CLJS.n(i1)?["(",$CLJS.p.h(a),")"].join(""):a};Gna=function(a,b,c){var d=$CLJS.OW(a,b);a=$CLJS.U0.j(a,b,d);var e=$CLJS.eB(c);return $CLJS.Re(function(f){return $CLJS.E.g($CLJS.eB($CLJS.T.h(f)),e)},a)};
Hna=function(a,b){var c=$CLJS.Sk.v(a,$CLJS.jQ,$CLJS.aE($CLJS.be,$CLJS.Cf),b);return $CLJS.zd($CLJS.ZE.h(a))?$CLJS.Sk.v(c,$CLJS.ZE,$CLJS.be,$CLJS.yW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vB,$CLJS.N,$CLJS.HW(b)],null))):c};k1=function(a,b,c){var d=$CLJS.HW(c);return $CLJS.R.l($CLJS.i0.j(a,b,c),$CLJS.XK,$CLJS.NZ,$CLJS.H([$CLJS.T,d,$CLJS.uE,d]))};l1=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);
$CLJS.m1=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var n1=function(){function a(d,e,f){var k=$CLJS.OW(d,e);k=$CLJS.DH($CLJS.Pk.g($CLJS.ch([f]),$CLJS.HW),$CLJS.jQ.h(k));if($CLJS.n(k))return k;throw $CLJS.hi($CLJS.pE("No expression named {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.kS,f,$CLJS.MF,d,$CLJS.ML,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.N0.m(null,$CLJS.vB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=n1.j(a,b,c);return $CLJS.O0.j(a,b,c)});$CLJS.P0.m(null,$CLJS.vB,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.Ej,$CLJS.VK,$CLJS.m1,$CLJS.GE.h(d),$CLJS.T,e,$CLJS.yE,e,$CLJS.uE,$CLJS.j0.j(a,b,c),$CLJS.yB,$CLJS.O0.j(a,b,c),$CLJS.XK,$CLJS.NZ],null)});$CLJS.J0.m(null,$CLJS.pB,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.J0.m(null,$CLJS.nB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.J0.m(null,$CLJS.oB,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.J0.m(null,$CLJS.FB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.J0.m(null,$CLJS.vB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.K0.m(null,$CLJS.vB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var i1=!1,Ina=new $CLJS.h(null,4,[$CLJS.ds,"+",$CLJS.rt,"-",$CLJS.es,"×",$CLJS.LG,"÷"],null),o1=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ds,$CLJS.rt,$CLJS.LG,$CLJS.es],null)),p1=null,q1=0,r1=0;;)if(r1<q1){var Jna=p1.X(null,r1);$CLJS.xF(Jna,l1);r1+=1}else{var s1=$CLJS.y(o1);if(s1){var t1=s1;if($CLJS.Ad(t1)){var u1=$CLJS.lc(t1),Kna=$CLJS.mc(t1),Lna=u1,Mna=$CLJS.D(u1);o1=Kna;p1=Lna;q1=Mna}else{var Nna=$CLJS.A(t1);$CLJS.xF(Nna,l1);o1=$CLJS.B(t1);p1=null;q1=0}r1=0}else break}
$CLJS.J0.m(null,l1,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.A(d);d=$CLJS.B(d);$CLJS.A(d);d=$CLJS.B(d);c=$CLJS.J.g(Ina,c);a:{var e=i1;i1=!0;try{var f=$CLJS.Jt([" ",$CLJS.gh(c)," "].join(""),$CLJS.hf.g($CLJS.hu($CLJS.j0,a,b),d));break a}finally{i1=e}f=void 0}return j1(f)});$CLJS.K0.m(null,l1,function(){return"expression"});
$CLJS.N0.m(null,$CLJS.KH,function(a,b,c){c=$CLJS.y(c);$CLJS.A(c);c=$CLJS.B(c);$CLJS.A(c);var d=$CLJS.B(c);return $CLJS.Qd($CLJS.zF,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=$CLJS.O0.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee($CLJS.O0.j(a,
b,u),k($CLJS.Lc(l)))}return null}},null,null)}(d)}())});$CLJS.J0.m(null,$CLJS.rJ,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.j0.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.b1.g(f,c).toLowerCase();0<f?f=$CLJS.fa.j?$CLJS.fa.j("+ %d %s",f,c):$CLJS.fa.call(null,"+ %d %s",f,c):(f=$CLJS.FA(f),f=$CLJS.fa.j?$CLJS.fa.j("- %d %s",f,c):$CLJS.fa.call(null,"- %d %s",f,c));f=j1(f);return[a," ",d.call(b,f)].join("")});
$CLJS.K0.m(null,$CLJS.rJ,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.L0.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.b1.g(e,c).toLowerCase();0<e?e=$CLJS.fa.j?$CLJS.fa.j("plus_%s_%s",e,c):$CLJS.fa.call(null,"plus_%s_%s",e,c):(e=$CLJS.FA(e),e=$CLJS.fa.j?$CLJS.fa.j("minus_%d_%s",e,c):$CLJS.fa.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.J0.m(null,$CLJS.sG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.j0.v(a,b,e,d)});$CLJS.K0.m(null,$CLJS.sG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.L0.j(a,b,d)});
$CLJS.Ona=function(){function a(d,e,f,k){e=$CLJS.n(e)?e:-1;if($CLJS.n(Gna(d,e,f)))throw $CLJS.hi("Expression name conflicts with a column in the same query stage",new $CLJS.h(null,1,[$CLJS.kS,f],null));return $CLJS.ZW.l(d,e,Hna,$CLJS.H([$CLJS.IW($CLJS.AW.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.v=a;return c}();$CLJS.Pna=function(){function a(d,e){var f=$CLJS.Fe($CLJS.jQ.h($CLJS.OW(d,e)));return null==f?null:$CLJS.Rk.g($CLJS.hu(k1,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.v1=function(){function a(d,e){return $CLJS.Fe($CLJS.jQ.h($CLJS.OW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.xW.m(null,$CLJS.vB,function(a){return a});
$CLJS.Qna=function(){function a(d,e,f){var k=$CLJS.eg.g($CLJS.N,$CLJS.dm(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HW(t),m],null)},$CLJS.v1.g(d,e))),l=$CLJS.OW(d,e);d=$CLJS.U0.j(d,e,l);return $CLJS.Fe($CLJS.h1(function(m){return $CLJS.Va(f)||$CLJS.Nk.g($CLJS.XK.h(m),$CLJS.NZ)||$CLJS.J.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Rna=function(){function a(d,e,f){return $CLJS.zW(k1(d,e,n1.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();