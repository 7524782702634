var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var BW,Zka,$ka,JW,ala,bla,dla,FW,ela,cla;BW=function(a){return a+1};$CLJS.CW=function(a){if("string"===typeof a){var b=$CLJS.nh(/[+-]?\d+/,a);return $CLJS.n(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.jm(a));};
Zka=function(a){var b=new $CLJS.Ea,c=$CLJS.Eu;$CLJS.Eu=new $CLJS.sc(b);try{var d=$CLJS.Eu,e=$CLJS.Va($CLJS.Mv(d)),f=$CLJS.Eu;$CLJS.Eu=e?$CLJS.Dv(d):d;try{d=$CLJS.Ov;$CLJS.Ov=!0;try{$CLJS.Qv(a)}finally{$CLJS.Ov=d}$CLJS.E.g(0,$CLJS.Lu($CLJS.Eu,$CLJS.Nu))||$CLJS.ac($CLJS.Eu,"\n");$CLJS.Ku()}finally{$CLJS.Eu=f}$CLJS.uh($CLJS.p.h(b))}finally{$CLJS.Eu=c}};$CLJS.DW=function(a,b){return $CLJS.Se.j($CLJS.fa,a,b)};
$CLJS.EW=function(a){var b=$CLJS.Av;$CLJS.Av=120;try{var c=new $CLJS.Ea,d=$CLJS.Qa,e=$CLJS.Ra;$CLJS.Qa=!0;$CLJS.Ra=function(f){return c.append(f)};try{Zka(a)}finally{$CLJS.Ra=e,$CLJS.Qa=d}return $CLJS.p.h(c)}finally{$CLJS.Av=b}};
$ka=function(a){a=$CLJS.O(a);var b=$CLJS.J.j(a,$CLJS.NV,$CLJS.Td),c=$CLJS.J.j(a,$CLJS.KV,function(f,k){return[$CLJS.p.h(f),"_",$CLJS.p.h(k)].join("")}),d=$CLJS.Xe($CLJS.N),e=$CLJS.Xe($CLJS.N);return function(){function f(m,t){for(;;){var u=b.h?b.h(t):b.call(null,t),v=function(){var z=new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),C=$CLJS.q(d);return C.h?C.h(z):C.call(null,z)}();if($CLJS.n(v))return v;var x=$CLJS.J.g($CLJS.Oh.v(e,$CLJS.Sk,u,$CLJS.aE(BW,0)),u);if($CLJS.E.g(x,1))return $CLJS.Oh.v(d,$CLJS.R,
new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),t),t;t=function(){var z=t,C=$CLJS.p.h(x);return c.g?c.g(z,C):c.call(null,z,C)}()}}function k(m){var t=l,u=t.g;null==FW&&(FW=$CLJS.Xe(0));var v=$CLJS.Fh.h([$CLJS.p.h("G__"),$CLJS.p.h($CLJS.Oh.g(FW,BW))].join(""));return u.call(t,v,m)}var l=null;l=function(m,t){switch(arguments.length){case 1:return k.call(this,m);case 2:return f.call(this,m,t)}throw Error("Invalid arity: "+arguments.length);};l.h=k;l.g=f;return l}()};
$CLJS.GW=function(a){return $CLJS.zd(a)&&1<$CLJS.D(a)&&$CLJS.A(a)instanceof $CLJS.M&&$CLJS.xd($CLJS.hd(a))&&$CLJS.Hd($CLJS.hd(a),$CLJS.GE)};$CLJS.HW=function(a){return $CLJS.GW(a)?$CLJS.yE.h($CLJS.NE(a)):null};$CLJS.IW=function(a,b){return $CLJS.OE($CLJS.GW(a)?a:new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,2,[$CLJS.GE,$CLJS.p.h($CLJS.QE()),$CLJS.Fi,$CLJS.UE(a)],null),a],null),$CLJS.R,$CLJS.H([$CLJS.yE,b]))};
JW=function(a,b,c){var d=$CLJS.Hd(a,b)?$CLJS.Sk.j(a,b,function(e){return $CLJS.zd(e)&&$CLJS.E.g($CLJS.A(e),$CLJS.Gs)?$CLJS.Df($CLJS.bf(1,e)):new $CLJS.P(null,1,5,$CLJS.Q,[e],null)}):a;return $CLJS.Hd(a,b)?$CLJS.IV(d,$CLJS.Ee([b,c])):d};ala=function(a){var b=$CLJS.em(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oP,$CLJS.SQ],null));b=KW.h?KW.h(b):KW.call(null,b);return $CLJS.yW($CLJS.R.l(JW($CLJS.Hk.l(a,$CLJS.oP,$CLJS.H([$CLJS.SQ])),$CLJS.aS,$CLJS.zL),$CLJS.Ej,$CLJS.rL,$CLJS.H([$CLJS.wL,b])))};
bla=function(a){return $CLJS.Rk.g(ala,a)};dla=function(a){return $CLJS.n(a)?$CLJS.R.j($CLJS.Sk.j($CLJS.Ml(a)?new $CLJS.h(null,1,[$CLJS.LW,a],null):a,$CLJS.LW,function(b){return $CLJS.Rk.g(function(c){return $CLJS.R.j($CLJS.IA(c,$CLJS.iB),$CLJS.Ej,$CLJS.VK)},b)}),$CLJS.Ej,cla):null};
$CLJS.MW=function(a){if($CLJS.E.g($CLJS.Ej.h(a),$CLJS.qW))return a;var b=$CLJS.mj.h(a);b=b instanceof $CLJS.M?b.T:null;switch(b){case "native":return $CLJS.gl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Ej,$CLJS.qW,$CLJS.wL,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.gl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.Ej,$CLJS.kW],null),$CLJS.IV($CLJS.tQ.h(a),new $CLJS.h(null,1,[$CLJS.MF,$CLJS.tQ],null))]))],null)],null),$CLJS.Hk.l(a,$CLJS.mj,$CLJS.H([$CLJS.tQ]))]));case "query":return $CLJS.gl.l($CLJS.H([new $CLJS.h(null,
2,[$CLJS.Ej,$CLJS.qW,$CLJS.wL,KW($CLJS.MF.h(a))],null),$CLJS.Hk.l(a,$CLJS.mj,$CLJS.H([$CLJS.MF]))]));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};$CLJS.NW=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.wL);var c=0>b?$CLJS.D(a)+b:b;if(c>=$CLJS.D(a)||0>c)throw $CLJS.hi($CLJS.pE("Stage {0} does not exist",$CLJS.H([b])),new $CLJS.h(null,1,[ela,$CLJS.D(a)],null));return c};
$CLJS.OW=function(a,b){a=$CLJS.MW(a);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.wL);return $CLJS.J.g($CLJS.Df(c),$CLJS.NW(a,b))};$CLJS.PW=function(a){return"string"===typeof a&&(a=$CLJS.oh(/^card__(\d+)$/,a),$CLJS.n(a))?($CLJS.I(a,0,null),a=$CLJS.I(a,1,null),$CLJS.CW(a)):null};$CLJS.RW=function(){return $CLJS.Pk.g(QW,$ka($CLJS.H([$CLJS.NV,$CLJS.eB,$CLJS.KV,function(a,b){return QW.h([$CLJS.p.h(a),"_",$CLJS.p.h(b)].join(""))}])))};FW=null;
$CLJS.SW=new $CLJS.M("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);$CLJS.TW=new $CLJS.M("metabase.lib.util","cannot-remove-final-join-condition","metabase.lib.util/cannot-remove-final-join-condition",-470911224);$CLJS.UW=new $CLJS.M(null,"join","join",-758861890);$CLJS.VW=new $CLJS.M(null,"stage","stage",1843544772);ela=new $CLJS.M(null,"num-stages","num-stages",1426797535);$CLJS.LW=new $CLJS.M(null,"columns","columns",1998437288);
$CLJS.WW=new $CLJS.M("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);$CLJS.XW=new $CLJS.M(null,"table","table",-564943036);cla=new $CLJS.M("metadata","results","metadata/results",330329298);$CLJS.YW=new $CLJS.M("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);var KW,QW;
KW=function KW(a){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.SQ),d=$CLJS.J.g(a,$CLJS.aP);c=$CLJS.n(c)?KW.h?KW.h(c):KW.call(null,c):$CLJS.Cf;d=dla(d);var e=$CLJS.D(c)-1;c=$CLJS.n($CLJS.n(d)?!(0>e):d)?$CLJS.fM(c,new $CLJS.P(null,2,5,$CLJS.Q,[e,$CLJS.xV],null),d):c;d=$CLJS.n($CLJS.tQ.h(a))?$CLJS.kW:$CLJS.dW;a=$CLJS.gl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.Ej,d],null),$CLJS.Hk.l(a,$CLJS.SQ,$CLJS.H([$CLJS.aP]))]));a=$CLJS.y($CLJS.NN.h(a))?$CLJS.Sk.j(a,$CLJS.NN,bla):a;a=JW(a,$CLJS.UF,$CLJS.rW);return $CLJS.be.g(c,a)};
$CLJS.ZW=function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e,f){c=$CLJS.MW(c);c=$CLJS.O(c);var k=$CLJS.J.g(c,$CLJS.wL);d=$CLJS.NW(c,d);e=$CLJS.Se.N($CLJS.Sk,$CLJS.Df(k),d,e,f);return $CLJS.R.j(c,$CLJS.wL,e)}a.A=3;a.B=function(c){var d=$CLJS.A(c);c=$CLJS.B(c);var e=$CLJS.A(c);c=$CLJS.B(c);var f=$CLJS.A(c);c=$CLJS.Lc(c);return b(d,e,f,c)};a.l=
b;return a}();
QW=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.wW.str(d,0)>>>0).toString(16);;)if(8>$CLJS.D(f))f=["0",$CLJS.p.h(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.h(f)].join("")}function b(d){return c.g?c.g(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.g=a;return c}();$CLJS.$W=RegExp(" id$","i");