var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var dfa,efa,ffa,gfa,hfa,ifa,jfa,WG,kfa,lfa,mfa,nfa,ofa,pfa,qfa,rfa,YG,sfa;$CLJS.RG=new $CLJS.M(null,"supported-field","supported-field",-2061545519);dfa=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.SG=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);efa=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
ffa=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.TG=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.UG=new $CLJS.M(null,"column-name","column-name",551523580);gfa=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.VG=new $CLJS.M(null,"display-info","display-info",-816930907);hfa=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);
ifa=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);jfa=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);WG=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);kfa=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);lfa=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);mfa=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);
nfa=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);ofa=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);pfa=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);qfa=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.XG=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);rfa=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);
YG=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);$CLJS.ZG=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);sfa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.GF($CLJS.Yx,$CLJS.H([$CLJS.rt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eF],null)],null)],null)],null)]));$CLJS.GF($CLJS.qG,$CLJS.H([$CLJS.rt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eF],null)],null)],null)],null)]));
$CLJS.EF($CLJS.jG,$CLJS.H([$CLJS.rt,$CLJS.WD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));$CLJS.EF($CLJS.VF,$CLJS.H([$CLJS.rt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eF],null)],null)]));$CLJS.EF($CLJS.yG,$CLJS.H([$CLJS.rt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null)]));
$CLJS.EF($CLJS.jk,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null)],null)]));$CLJS.xF($CLJS.jk,$CLJS.jF);$CLJS.EF($CLJS.DG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));$CLJS.xF($CLJS.DG,$CLJS.jF);$CLJS.EF($CLJS.Zn,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null)],null)]));$CLJS.xF($CLJS.Zn,$CLJS.jF);
$CLJS.X(WG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.h(null,1,[$CLJS.yt,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.yt,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.EF($CLJS.aG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WG],null)]));$CLJS.xF($CLJS.aG,$CLJS.jF);
$CLJS.EF($CLJS.wG,$CLJS.H([$CLJS.rt,$CLJS.WD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null)]));$CLJS.EF($CLJS.FG,$CLJS.H([$CLJS.rt,$CLJS.WD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));$CLJS.EF($CLJS.CG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));
$CLJS.EF($CLJS.MG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));$CLJS.xF($CLJS.CG,$CLJS.jF);$CLJS.xF($CLJS.MG,$CLJS.jF);$CLJS.EF($CLJS.KG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null)]));$CLJS.xF($CLJS.KG,$CLJS.jF);
$CLJS.EF($CLJS.WF,$CLJS.H([$CLJS.rt,$CLJS.WD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));$CLJS.X(YG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.zs,rfa,pfa,ifa,efa,dfa,hfa,jfa,kfa,lfa,ofa,mfa,sfa,qfa,ffa,nfa,$CLJS.Dl],null));$CLJS.X($CLJS.SG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YG],null)],null));
$CLJS.$G=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.iG,$CLJS.Yx,$CLJS.TG,!1,$CLJS.XG,$CLJS.KF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.uE,$CLJS.RE("Count of rows"),$CLJS.UG,$CLJS.RE("Count"),$CLJS.St,$CLJS.RE("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.iG,$CLJS.CG,$CLJS.RG,$CLJS.fk,$CLJS.TG,!0,$CLJS.XG,$CLJS.KF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.uE,$CLJS.RE("Sum of ..."),$CLJS.UG,$CLJS.RE("Sum"),$CLJS.St,$CLJS.RE("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.iG,$CLJS.jG,$CLJS.RG,$CLJS.fk,$CLJS.TG,!0,$CLJS.XG,$CLJS.KF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.uE,$CLJS.RE("Average of ..."),$CLJS.UG,$CLJS.RE("Average"),$CLJS.St,$CLJS.RE("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.iG,$CLJS.DG,$CLJS.RG,$CLJS.fk,$CLJS.TG,!0,$CLJS.XG,$CLJS.lG,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.uE,$CLJS.RE("Median of ..."),$CLJS.UG,$CLJS.RE("Median"),$CLJS.St,$CLJS.RE("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.iG,$CLJS.VF,$CLJS.RG,$CLJS.li,$CLJS.TG,!0,$CLJS.XG,$CLJS.KF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.uE,$CLJS.RE("Number of distinct values of ..."),$CLJS.UG,$CLJS.RE("Distinct values"),$CLJS.St,$CLJS.RE("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.iG,$CLJS.MG,$CLJS.RG,$CLJS.fk,$CLJS.TG,!0,$CLJS.XG,$CLJS.KF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.uE,$CLJS.RE("Cumulative sum of ..."),
$CLJS.UG,$CLJS.RE("Sum"),$CLJS.St,$CLJS.RE("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.iG,$CLJS.qG,$CLJS.TG,!1,$CLJS.XG,$CLJS.KF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.uE,$CLJS.RE("Cumulative count of rows"),$CLJS.UG,$CLJS.RE("Count"),$CLJS.St,$CLJS.RE("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.iG,$CLJS.FG,$CLJS.RG,$CLJS.fk,$CLJS.TG,
!0,$CLJS.XG,$CLJS.zG,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.uE,$CLJS.RE("Standard deviation of ..."),$CLJS.UG,$CLJS.RE("SD"),$CLJS.St,$CLJS.RE("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.iG,$CLJS.Zn,$CLJS.RG,$CLJS.Yi,$CLJS.TG,!0,$CLJS.XG,$CLJS.KF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.uE,$CLJS.RE("Minimum of ..."),$CLJS.UG,$CLJS.RE("Min"),$CLJS.St,$CLJS.RE("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.iG,$CLJS.jk,$CLJS.RG,$CLJS.Yi,$CLJS.TG,!0,$CLJS.XG,$CLJS.KF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.uE,$CLJS.RE("Maximum of ..."),$CLJS.UG,$CLJS.RE("Max"),$CLJS.St,$CLJS.RE("Maximum value of a column")],null)}],null)],null);
$CLJS.X(gfa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.ZG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iG,$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ws],null),$CLJS.hf.h($CLJS.iG),$CLJS.$G)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RG,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Xi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TG,$CLJS.Ps],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.XG,$CLJS.Xi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VG,$CLJS.md],null)],null));