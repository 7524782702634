var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var XI,ZI,aJ,mJ,qJ,yJ;$CLJS.WI=new $CLJS.M(null,"get-month","get-month",-369374731);XI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.YI=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);ZI=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.$I=new $CLJS.M(null,"iso","iso",-1366207543);aJ=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.bJ=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.cJ=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.dJ=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.eJ=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.fJ=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.gJ=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.hJ=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.iJ=new $CLJS.M(null,"us","us",746429226);$CLJS.jJ=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.kJ=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.lJ=new $CLJS.M(null,"now","now",-1650525531);mJ=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.nJ=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.oJ=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.pJ=new $CLJS.M(null,"get-week","get-week",-888059349);
qJ=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.rJ=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.sJ=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.tJ=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.uJ=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.vJ=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.wJ=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.xJ=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);yJ=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.zJ=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.AJ=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.BJ=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.CJ=new $CLJS.M(null,"target","target",253001721);$CLJS.EF($CLJS.hJ,$CLJS.H([$CLJS.rt,$CLJS.kD,$CLJS.Ls,$CLJS.kH]));$CLJS.TE.m(null,$CLJS.eJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.UE(a);return $CLJS.vd(a)?(a=$CLJS.nu.g(a,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.$C,null,$CLJS.zD,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.A(a):a):a});$CLJS.xF($CLJS.eJ,$CLJS.jF);
for(var DJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rJ,$CLJS.xJ],null)),EJ=null,FJ=0,GJ=0;;)if(GJ<FJ){var HJ=EJ.X(null,GJ);$CLJS.EF(HJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null),$CLJS.Ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kH],null)]));$CLJS.xF(HJ,$CLJS.eJ);GJ+=1}else{var IJ=$CLJS.y(DJ);if(IJ){var JJ=IJ;if($CLJS.Ad(JJ)){var KJ=$CLJS.lc(JJ),Lga=$CLJS.mc(JJ),Mga=KJ,Nga=$CLJS.D(KJ);DJ=Lga;EJ=Mga;FJ=Nga}else{var LJ=$CLJS.A(JJ);$CLJS.EF(LJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.bF],null),$CLJS.Ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kH],null)]));$CLJS.xF(LJ,$CLJS.eJ);DJ=$CLJS.B(JJ);EJ=null;FJ=0}GJ=0}else break}
for(var MJ=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.cJ,$CLJS.WI,$CLJS.oJ,$CLJS.wJ,$CLJS.sJ,$CLJS.bJ,$CLJS.zJ],null)),NJ=null,OJ=0,PJ=0;;)if(PJ<OJ){var Oga=NJ.X(null,PJ);$CLJS.EF(Oga,$CLJS.H([$CLJS.rt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));PJ+=1}else{var QJ=$CLJS.y(MJ);if(QJ){var RJ=QJ;if($CLJS.Ad(RJ)){var SJ=$CLJS.lc(RJ),Pga=$CLJS.mc(RJ),Qga=SJ,Rga=$CLJS.D(SJ);MJ=Pga;NJ=Qga;OJ=Rga}else{var Sga=$CLJS.A(RJ);$CLJS.EF(Sga,$CLJS.H([$CLJS.rt,
$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));MJ=$CLJS.B(RJ);NJ=null;OJ=0}PJ=0}else break}$CLJS.EF($CLJS.gJ,$CLJS.H([$CLJS.rt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hH],null)]));
for(var TJ=$CLJS.y(new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.zJ,null,$CLJS.sJ,null,$CLJS.wJ,null,$CLJS.tJ,null,$CLJS.bJ,null,$CLJS.WI,null,$CLJS.cJ,null,$CLJS.oJ,null],null),null)),UJ=null,VJ=0,WJ=0;;)if(WJ<VJ){var Tga=UJ.X(null,WJ);$CLJS.EF(Tga,$CLJS.H([$CLJS.rt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));WJ+=1}else{var XJ=$CLJS.y(TJ);if(XJ){var YJ=XJ;if($CLJS.Ad(YJ)){var ZJ=$CLJS.lc(YJ),Uga=$CLJS.mc(YJ),Vga=ZJ,Wga=$CLJS.D(ZJ);
TJ=Uga;UJ=Vga;VJ=Wga}else{var Xga=$CLJS.A(YJ);$CLJS.EF(Xga,$CLJS.H([$CLJS.rt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));TJ=$CLJS.B(YJ);UJ=null;VJ=0}WJ=0}else break}$CLJS.X(yJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,$CLJS.$I,$CLJS.iJ,$CLJS.nJ],null));
$CLJS.GF($CLJS.pJ,$CLJS.H([$CLJS.rt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yJ],null)],null)],null)],null)]));
$CLJS.X($CLJS.uJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,$CLJS.zE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.yt,"valid timezone ID",$CLJS.At,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Dj);return["invalid timezone ID: ",$CLJS.Mh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.bH($CLJS.Jd,$CLJS.VI.tz.names())),$CLJS.MI],null)],null));
$CLJS.GF($CLJS.YI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uJ],null)],null)],null)],
null)]));$CLJS.xF($CLJS.YI,$CLJS.eJ);$CLJS.EF($CLJS.lJ,$CLJS.H([$CLJS.rt,$CLJS.cD]));$CLJS.X(aJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.yt,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Re(function(b){return $CLJS.zC(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$C,$CLJS.zD],null))}],null)],null));
$CLJS.X(XI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yB,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aJ],null)],null)],null)],null));
$CLJS.QG.g($CLJS.dJ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.yt,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.dJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.yt,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jH],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.yt,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.$v],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eH],null)],null)],null)],null)],null)],null));
$CLJS.TE.m(null,$CLJS.dJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.$v)?$CLJS.E.g(a,$CLJS.ci)?$CLJS.zD:$CLJS.n($CLJS.wF($CLJS.jH,a))?$CLJS.$C:$CLJS.zD:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.nh($CLJS.HI,b))?$CLJS.$C:$CLJS.n($CLJS.nh($CLJS.II,b))?$CLJS.$C:null:null;if($CLJS.n(a))return a;b=$CLJS.UE(b);b=$CLJS.vd(b)?$CLJS.eg.j($CLJS.bh,$CLJS.kf(function(c){return $CLJS.zC(c,$CLJS.Wj)}),b):b;return $CLJS.vd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.A(b):b});$CLJS.X(qJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.$v],null),$CLJS.Ls],null));
$CLJS.GF($CLJS.jJ,$CLJS.H([$CLJS.rt,$CLJS.zD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.si,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kH],null)],null)],null)],null)]));
$CLJS.EF($CLJS.My,$CLJS.H([$CLJS.rt,$CLJS.ZD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iH],null)]));$CLJS.X(ZI,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.ws,$CLJS.gH,$CLJS.Ai,$CLJS.mk,$CLJS.fJ,$CLJS.AJ,$CLJS.BJ,$CLJS.Gj,$CLJS.Gi,$CLJS.wi,$CLJS.ij,$CLJS.dH],null));$CLJS.X(mJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,$CLJS.$I,$CLJS.iJ,$CLJS.nJ],null));
$CLJS.GF($CLJS.vJ,$CLJS.H([$CLJS.rt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.si,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mJ],null)],null)],
null)],null)]));